import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useDarkMode } from './DarkModeContext';
export default function PageNotFound() {

    const { darkMode, toggleDarkMode } = useDarkMode();

    // Automatically enable dark mode when the NotFound component mounts
    /*     useEffect(() => {
            console.log('NotFound component mounted');
    
            if (!darkMode) { // Check if dark mode is not already enabled
                toggleDarkMode(); // Enable dark mode
            }
        }, [darkMode, toggleDarkMode]); // Include dependencies to avoid linting warnings */

    return (
        <div className="not-found">
            <p> <Link to="/">error 404 :-(</Link></p>

        </div>
    );
}