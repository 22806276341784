import React, { useState, useEffect } from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from "@contentful/rich-text-types";

import client from '../contentfulClientToken';

export default function InfoSection() {
    const [infos, setInfos] = useState([]);

    useEffect(() => {
        client.getEntry('2kGSE8AhpOAVeBZNUXc5bi')
            .then(response => {
                setInfos(response.fields);
                /*   console.log(response.fields); */
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const renderOptions = {
        renderText: text =>
            text.split('\n').reduce((children, textSegment, index) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []),
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data;
                return (
                    <a href={uri} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                );
            },
        },
    };

    return (
        <>
            <div className="info-section">
                <div className="general-info"> {documentToReactComponents(infos.generalInfo, renderOptions)}</div>
                <div className="selected-clients">{documentToReactComponents(infos.selectedClients, renderOptions)}</div>
                <div className="publications">{documentToReactComponents(infos.publications, renderOptions)}</div>

                <div className="dev-credit">
                    <p>web development <a href="https://ff87c3.eu" target="_blank" rel="noopener noreferrer">@ff87c3.eu</a></p>
                </div>

            </div>



        </>
    );
}