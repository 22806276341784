import { useState, useEffect } from "react";


import { useDarkMode } from './DarkModeContext';
export default function Smiley() {
    // const [isDarkMode, setIsDarkMode] = useState(false);

    const { darkMode, toggleDarkMode } = useDarkMode();
    const [smiley, setSmiley] = useState(":-)"); // Initial smiley

    useEffect(() => {
        setSmiley(darkMode ? ":-(" : ":-)");
    }, [darkMode]);

    return (
        <div onClick={toggleDarkMode} className="smiley">
            <p>{smiley}</p>
        </div>
    );
}
