import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import client from '../contentfulClientToken';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default function MenuList() {
    const [shuffledPages, setShuffledPages] = useState([]);
    const [hoveredImage, setHoveredImage] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [menuItems, setMenuItems] = useState(shuffledPages);

    // Update window width on resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchPages = async () => {
            const response = await client.getEntries({ content_type: 'projects' });

            // Shuffle the received items
            const shuffled = [...response.items].sort(() => Math.random() - 0.5);
            setShuffledPages(shuffled);
            //setShuffledPages([...response.items])
        };
        fetchPages();
    }, []);

    // Ensure menuItems updates when shuffledPages updates
    useEffect(() => {
        setMenuItems(shuffledPages);
    }, [shuffledPages]);

    useEffect(() => {
        const menuListElement = document.getElementById("menu-list");
        if (!menuListElement) return;

        const handleInfiniteScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = menuListElement;

            // Check if scrolled to bottom
            if (scrollTop + clientHeight >= scrollHeight - 20) {
                // console.log("Appending items"); // Debugging
                setMenuItems((prevItems) => [...prevItems, ...shuffledPages]);
            }
        };

        menuListElement.addEventListener("scroll", handleInfiniteScroll);
        return () => menuListElement.removeEventListener("scroll", handleInfiniteScroll);
    }, [menuItems, shuffledPages]);


    // Resize image URL based on window width
    /*     const getImageUrl = (url) => {
    
            let width = 700; // default width
            if (windowWidth < 768) {
                width = 500; // width for mobile devices
            } else if (windowWidth < 1200) {
                width = 600; // width for tablets
            }
    
            // Adjust width for retina screens
            const pixelRatio = window.devicePixelRatio || 1;
            width *= pixelRatio;
    
            return `https:${url}?w=${width}`;
        }; */

    /*   const getImageUrl = (image) => {
          const originalWidth = image.fields.file.details.image.width;
          const originalHeight = image.fields.file.details.image.height;
  
          // const targetHeight = 600; // Fixed height
          // const targetWidth = Math.round((targetHeight / originalHeight) * originalWidth); // Keep aspect ratio
  
          let targetHeight = '';
  
          if (windowWidth < 768) {
              targetHeight = 400; // width for mobile devices
          } else {
              targetHeight = 600;
          }
  
          const targetWidth = Math.round((targetHeight / originalHeight) * originalWidth); // Keep aspect ratio
  
          return `https:${image.fields.file.url}?w=${targetWidth}&h=${targetHeight}`;
      }; */

    const getImageUrl = (image) => {
        const originalWidth = image.fields.file.details.image.width;
        const originalHeight = image.fields.file.details.image.height;

        const windowWidth = window.innerWidth; // Define window width

        let targetHeight;

        if (windowWidth < 768) {
            targetHeight = 400; // Height for mobile devices
        } else {
            targetHeight = 600; // Height for larger screens
        }

        let pixelRatio = window.devicePixelRatio || 1;
        pixelRatio = Math.min(pixelRatio, 2.5); // Cap the pixel ratio at 2

        const requestHeight = targetHeight * pixelRatio; // Request higher-res image
        const targetWidth = Math.round((requestHeight / originalHeight) * originalWidth); // Maintain aspect ratio

        //return `https:${image.fields.file.url}?w=${targetWidth}&h=${requestHeight}&fm=jpg`;
        return `https:${image.fields.file.url}?w=${targetWidth}&h=${requestHeight}`;
    };

    return (
        <>
            <div id='menu-list'>
                {menuItems.map((section, index) => (
                    <div
                        key={`${section.sys.id}-${index}`} // Updated key to ensure uniqueness after items are appended
                        onMouseEnter={() => {
                            const imageObject = section.fields.sliderPictures[0]; // The full image object
                            const resizedImageUrl = getImageUrl(imageObject);
                            setHoveredImage(resizedImageUrl);
                            //console.log(resizedImageUrl);
                        }}
                        onMouseLeave={() => setHoveredImage(null)}>
                        <Link to={`/${section.fields.slug}`}>
                            {documentToReactComponents(section.fields.projectInfo)}
                        </Link>
                    </div>

                ))}
            </div>

            <div className="hover-container">
                {hoveredImage && (
                    <img
                        src={hoveredImage}
                        alt="Francois Quillacq photography"
                    />
                )}
            </div>

        </>

    );
};

