import React, { useState } from "react";
import { Link } from 'react-router-dom';

export default function Buttons() {


    return (

        <div className="buttons">

            <div className="francois-logo">
                <Link to="/">
                    <p>François Quillacq</p>
                </Link>
            </div>

            <div className="info-button">
                <Link to="/info">
                    <p>info</p>
                </Link>
            </div>

            <div className="index-button">
                <Link to="/">
                    <p>index</p>
                </Link>
            </div>

        </div>

    );
};