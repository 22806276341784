import Slider from "./Slider";

const Page = ({ data }) => {
    return (
        <div>
            {data.sliderPictures && data.sliderPictures.length > 0 && <Slider images={data.sliderPictures} />}
        </div>
    );
};

export default Page;