import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.scss';
import client from './contentfulClientToken';

import Buttons from './components/Buttons';
import InfoSection from './components/InfoSection';
import Smiley from './components/Smiley';
import Page from './components/Page';
import MenuList from './components/MenuList';
import PageNotFound from './components/PageNotFound'

import { DarkModeProvider } from './components/DarkModeContext';

function App() {

  /*   const { data: pages, isLoading } = useQuery('pages', fetchPages);
  
    if (isLoading) return <p>Loading...</p>; */

  window.addEventListener(
    'contextmenu',
    function (e) {
      e.preventDefault();
    },
    false
  );

  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      const entries = await client.getEntries({ content_type: 'projects' });
      /*       console.log(entries); */
      setPages(entries.items);
    };

    fetchPages();
  }, []);

  return (

    <DarkModeProvider>
      <Router>

        <Smiley />
        <Buttons />

        <Routes>

          <Route path="/info" element={<InfoSection />} />
          <Route path="/" element={<MenuList />} />
          {pages.map((page) => (
            <Route
              key={page.sys.id}
              path={`/${page.fields.slug}`}
              element={<Page data={page.fields} />}
            />
          ))}
          <Route path="*" element={<PageNotFound />} />

        </Routes>
      </Router>
    </DarkModeProvider>

  );
}

export default App;
