import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Keyboard, Mousewheel } from 'swiper/modules';

import { useDarkMode } from './DarkModeContext';
export default function Slider({ images }) {

    const { darkMode } = useDarkMode();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const getImageUrl = (image) => {
        const originalWidth = image.fields.file.details.image.width;
        const originalHeight = image.fields.file.details.image.height;

        const windowWidth = window.innerWidth; // Define window width

        let targetHeight;

        if (windowWidth < 768) {
            targetHeight = 400; // Height for mobile devices
        } else {
            targetHeight = 600; // Height for larger screens
        }

        let pixelRatio = window.devicePixelRatio || 1;
        pixelRatio = Math.min(pixelRatio, 2.5); // Cap the pixel ratio at 2

        const requestHeight = targetHeight * pixelRatio; // Request higher-res image
        const targetWidth = Math.round((requestHeight / originalHeight) * originalWidth); // Maintain aspect ratio

/*         return `https:${image.fields.file.url}?w=${targetWidth}&h=${requestHeight}&fm=jpg`;
 */        return `https:${image.fields.file.url}?w=${targetWidth}&h=${requestHeight}`;

    };


    const handleImageLoad = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageDimensions({ width: naturalWidth, height: naturalHeight });
        /*   setLoadedImagesCount((prevCount) => prevCount + 1); */
    };

    useEffect(() => {
        const prevCursor = document.querySelector(".swiper-button-prev");
        const nextCursor = document.querySelector(".swiper-button-next");

        if (prevCursor && nextCursor) {
            prevCursor.style.cursor = darkMode
                ? 'url("./content/cursor/prev-13-light.svg"), auto'
                : 'url("./content/cursor/prev-13-dark.svg"), auto';
            nextCursor.style.cursor = darkMode
                ? 'url("./content/cursor/next-14-light.svg"), auto'
                : 'url("./content/cursor/next-14-dark.svg"), auto';
        }
    }, [darkMode]);

    return (

        <div className="mySwiperContainer">
            <Swiper
                navigation={true}
                modules={[Navigation, Keyboard, Mousewheel]}
                loop={true}
                className="mySwiper"
                keyboard={true}
                touchEventsTarget="wrapper"
                simulateTouch={true}
                allowTouchMove={true}
                mousewheel={true}
                lazyPreloadPrevNext={1}
            >
                {images.map((media, index) => {

                    if (!media?.fields?.file?.url) {
                        return null; // Skip rendering if asset is invalid
                    }


                    const isVideo = /\.(mp4|webm|ogg|mov)$/i.test(media.fields.file.url);

                    return (
                        <SwiperSlide key={index}>
                            {isVideo ? (
                                <video
                                    /* controls */
                                    playsInline
                                    loop
                                    muted
                                    autoPlay
                                    width="100%"
                                    height="100%"
                                >
                                    <source
                                        src={`https:${media.fields.file.url}`}
                                        type={media.fields.file.contentType}
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                /*  <picture>
                                     <source
                                         srcSet={`${getImageUrl(media)}&fm=webp`}
                                         type="image/webp"
                                     /> */
                                <img
                                    src={getImageUrl(media)}
                                    alt={media.fields.title || "Francois Quillacq photography"}
                                    /*    loading="lazy" */
                                    onLoad={handleImageLoad}
                                    width={imageDimensions.width}
                                    height={imageDimensions.height}
                                />
                                /*  </picture> */
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>

    );
}